import React from "react"
import "./style.css"

const SeparatorLine = ({ width }) => {
  return (
    <div className="separatorLine__container">
      <div className="separatorLine" style={{ width: width }}></div>
    </div>
  )
}

export default SeparatorLine
