import React from "react"
import Button from "../Button/Button"
import "./style.css"
import boldToYellow from "../../utils/boldToYellow"
import {PortableText} from "@portabletext/react";

const TextWithBackgroundText = ({
  text,
  backgroundText,
  buttonText,
  buttonOnClick,
}) => {
  return (
    <div className={"avantFooter"}>
      <div className="centerRelativeBox">
        <div
          className="backgroundText"
          style={{ fontSize: `calc(80vw / ${backgroundText.length * 1.2})` }}
        >
          <h1>{backgroundText}</h1>
        </div>
        <div className="absolute-center row">
          <div className={"textContact"}>
            <PortableText value={text}/>
          </div>
        </div>
      </div>
      <Button
        text={buttonText}
        theme={"dark"}
        className="absolute-bottom"
        onClick={buttonOnClick}
      />
    </div>
  )
}

export default TextWithBackgroundText
