import React from "react"
import Button from "../Button/Button"
import "./style.css"
import * as HeroIcons from "react-icons/hi"
import { PortableText } from "@portabletext/react"

const kebabToPascal = (str) => {
  return str
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join("")
}

const Service = ({
                   icon,
                   title,
                   description,
                   buttonText,
                   buttonOnClick,
                 }) => {
  let Icon = HeroIcons["Hi"+kebabToPascal(icon.name)]
  if (!Icon) {
    Icon = HeroIcons.HiQuestionMarkCircle
  }
  return (
    <div className="service">
      <div className="service__iconCircle">
        <Icon size={70} color="#262626"/>
      </div>
      <h1 className="service__title">{title}</h1>
      <div className="service__description">
        <PortableText value={description}/>
      </div>

      <Button text={buttonText} onClick={buttonOnClick}/>
    </div>
  )
}

export default Service
