import React from "react"
import "./style.css"
import { ImQuotesLeft } from "react-icons/im"
import { GatsbyImage } from "gatsby-plugin-image"

const Review = ({ quote, authorImage, authorDescription }) => {
  return (
    <div className="review">
      <div className="review__quote">
        <ImQuotesLeft color="#000" size={60} />
      </div>
      <div className="review__text">
        <p>« {quote} »</p>
      </div>
      <div className="review__author">
        <GatsbyImage image={authorImage} width={80} height={80} />
        <p>{authorDescription}</p>
      </div>
    </div>
  )
}
export default Review
