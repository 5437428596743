import React from "react"
import PageFooter from "../components/PageFooter/PageFooter"
import PageHeader from "../components/PageHeader/PageHeader"
import CitationStar from "../components/CitationStar/CitationStar"
import "./services.css"
import Service from "../components/Service/Service"
import Review from "../components/Review/Review"
import SeparatorLine from "../components/SeparatorLine/SeparatorLine"
import TextWithBackgroundText from "../components/TextWithBackgroundText/TextWithBackgroundText"
import {graphql, navigate, useStaticQuery} from "gatsby"
import {getImage} from "gatsby-plugin-image"
import Seo from "../components/seo"

const Services = () => {
  const {sanityServicespage} = useStaticQuery(query)
  const {citation, catchphrase, review, services, header} =
    sanityServicespage


  return (
    <div style={{paddingTop: "100vh"}}>
      <Seo title="Services"/>
      <PageHeader
        titre={"Services"}
        sousTitre={"Accueil • Services"}
        media={header}
      />
      <CitationStar className="servicesCitation" text={citation}/>
      <div className="servicesList">
        {services.map(service => (
          <Service
            icon={service.icon}
            title={service.title}
            description={service._rawDescription}
            buttonText={service.buttonText}
            buttonOnClick={() => navigate("/" + service.link)}
          />
        ))}
      </div>
      <Review
        quote={review.text}
        authorImage={getImage(review.photo.asset)}
        authorDescription={review.title}
      />
      <SeparatorLine width={"75vw"}/>
      <TextWithBackgroundText
        text={catchphrase._rawText}
        backgroundText={catchphrase.backgroundText}
        buttonText={"Travaillons ensemble"}
        buttonOnClick={() => navigate("/contact")}
      />
      <PageFooter/>
    </div>
  )
}

const query = graphql`
    query {
        sanityServicespage {
            header {
                ...HeaderImage
                ...HeaderYoutubeVideo
            }
            citation
            catchphrase {
                backgroundText
                _rawText
            }
            review {
                text
                title
                photo {
                    asset{
                        gatsbyImageData(width: 80, height: 80, placeholder: BLURRED)
                    }
                }
            }
            services {
                _rawDescription
                link
                title
                buttonText
                icon {
                    name
                    provider
                }
            }
        }
    }
`

export const Head = () => <Seo title="Services"/>

export default Services
