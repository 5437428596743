import React from "react"
import "./style.css"
import { IoIosRemove, IoIosStar } from "react-icons/io"

const CitationStar = ({ text, className }) => {
  return (
    <div className={"citationStar " + className}>
      <div className="row">
        <IoIosRemove color="#fbb90e" size={50} />
        <IoIosStar color="#fbb90e" size={30} />
        <IoIosRemove color="#fbb90e" size={50} />
      </div>
      <p className="citationStar__citation">{text}</p>
    </div>
  )
}

export default CitationStar
